<template>
  <div class="auth-wrapper auth-v2 bg-white">
    <b-overlay :show="loading">
      <b-row class="auth-inner m-0">
        <!-- Left Text-->
        <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="8" lg="12" class="px-xl-2 mx-auto">
            <br />
            <a href="\" class="">
              <h1 class="brand-text text-primary">ELP</h1>
            </a>
            <br />
            <br />
            <h2 class="mb-1">Lupa Kata Sandi? 🔒</h2>
            <b-card-text class="mb-2" style="font-size: 12px">
              Masukan Email Kamu yang Telah Didaftarkan untuk Mengatur Ulang
              Kata Sandi
            </b-card-text>

            <!-- form -->
            <validation-observer ref="forgotPassword" #default="{ invalid }">
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="submit"
              >
                <b-form-group label="Email" label-for="forgot-password-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="forgot-password-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="forgot-password-email"
                      placeholder="Masukkan Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="submit"
                  :disabled="invalid"
                  variant="primary"
                  block
                >
                  Kirim
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <b-link :to="{ name: 'auth-login' }">
                <feather-icon icon="ChevronLeftIcon" />
                Kembali login
              </b-link>
            </p>
          </b-col>
          <p
            class="clearfix mb-0 mt-4"
            style="position: absolute; top: 93%; left: 5%"
          >
            <span class="float-md-left d-block d-md-inline-block mt-25">
              © {{ new Date().getFullYear() }} Einstein Learning Plus
              <span class="d-none d-sm-inline-block">
                . All rights Reserved
              </span>
            </span>
            <br />
          </p>
        </b-col>

        <!-- /Left Text-->

        <!-- Forgot password-->

        <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img fluid :src="imgUrl" alt="Forgot password V2" />
          </div>
        </b-col>

        <!-- /Forgot password-->
        <b-modal
          id="modal-scoped"
          ref="my-modal"
          centered
          title="Using Component Methods"
        >
          <template #modal-header="{ close }">
            <h5>Verifikasi email</h5>
          </template>

          <template #default="{ hide }">
            <h5>{{ message }}</h5>
          </template>

          <template #modal-footer="{ ok, cancel, hide }">
            <b-button size="lg" block variant="info" @click="visitEmail()">
              Lihat Email
            </b-button>
          </template>
        </b-modal>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BOverlay,
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BOverlay,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      loading: false,
      message: "",
      // validation
      required,
      email,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    visitEmail() {
      window.location.href = "https://mail.google.com/mail/u/0/#inbox";
    },
    submit() {
      this.$refs.forgotPassword.validate().then((success) => {
        if (success) {
          this.loading = true;
          const payload = {
            email: this.userEmail,
          };
          this.$store
            .dispatch("auth/forgotPassword", payload)
            .then((res) => {
              this.userEmail = "";
              this.loading = false;
              this.message = res.data.message;
              this.$refs["my-modal"].show();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Peringatan`,
                  icon: "AlertCircleIcon",
                  variant: "warning",
                  text: this.message,
                },
              });
            })
            .catch((error) => {
              this.loading = false;
              console.error("error", error);
              this.$refs.loginForm.setErrors(error);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error `,
                  icon: "XIcon",
                  variant: "danger",
                  text: error.response.data.message,
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
